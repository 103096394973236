import logo from "./assets/logo.png";
import "./App.css";
import p1 from "./assets/p1.png";
import p2 from "./assets/1.jpg";
import download from "./assets/download.png";
import c1 from "./assets/c1.png";
import c2 from "./assets/c2.png";
import c3 from "./assets/c3.png";
import c4 from "./assets/c4.png";
import s1 from "./assets/s1.png";
import s2 from "./assets/s2.png";
import s3 from "./assets/s3.png";
import s4 from "./assets/s4.png";
import s5 from "./assets/s5.png";
import android from "./assets/android.png";
import ios from "./assets/ios.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";

function App() {
  return (
    <>
      <header className="App-header">
        <div className="header-con">
          <img src={logo} alt="" />
        </div>
      </header>
      <section className="content">
        <div className="flexRow">
          <div>
            <img className="p1" src={p1} alt="装点你的桌面" />
            <div className="btn">
              <img src={download} alt="" />
              立即下载
            </div>
          </div>

          <img className="p2" src={p2} alt="装点你的桌面" />
        </div>
      </section>
      <section className="content">
        <div className="flexRow">
          <div className="list">
            <img src={c1} alt="" />
            <div>动、静壁纸</div>
          </div>
          <div className="list">
            <img src={c2} alt="" />
            <div>动态屏保</div>
          </div>
          <div className="list">
            <img src={c3} alt="" />
            <div>壁纸铃声</div>
          </div>
          <div className="list">
            <img src={c4} alt="" />
            <div>超多分类</div>
          </div>
        </div>
        <div className="btn btn2">立即下载</div>
      </section>

      <section className="content">
        <div className="bg">
          <div className="swpier-box">
            <Swiper
              pagination={true}
              modules={[Pagination, Navigation]}
              autoplay
            >
              <SwiperSlide>
                <img src={s1} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={s2} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={s3} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={s4} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={s5} alt="" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="flexRow">
          <div className="box">
            <img src={ios} alt="" />
            <div>iOS</div>
          </div>
          <div className="box">
            <img src={android} alt="" />
            <div>Android</div>
          </div>
        </div>
      </section>
      <footer className="footer">Copyright © 2024 深圳市搜虎网络科技有限公司 版权所有</footer>
    </>
  );
}

export default App;
